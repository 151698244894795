<template src="./index.html">

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay, VBTooltip, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  name: 'Index',
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BCard,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VueGoodTable,
    vSelect,
    BOverlay,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    studentExamination: {
      type: Object,
      default: () => ({
        studentExaminationRoomId: null,
        score: null,
        studentCreditClassId: null,
        subjectName: '',
      }),
    },
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      rows: [],
      columns: [
        {
          label: 'Môn thi',
          field: 'subjectName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Phòng thi',
          field: 'roomName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Điểm thi',
          field: 'score',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        // {
        //   label: 'Điểm sau khi thi lại',
        //   field: 'newScore',
        //   sortable: false,
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        // },
        {
          label: 'Trạng thái thi lại',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      totalRows: 0,
      filter: {
        courseId: null,
        courseSemesterId: null,
        studentId: getUser().studentId,
        itemsPerPage: 10,
        currentPage: 1,
      },
    }
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      courses: 'courses/courses',
      dataSource: 'student/examinationRooms',
      courseSemesters: 'dropdown/courseSemesters',
    }),
  },

  async created() {
    await this.getInit()
  },

  methods: {
    ...mapActions({
      getCourses: 'courses/getCoursesByStudentId',
      getDataSource: 'student/getListExamination',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      createStudentRemakeExamination: 'studentRemakeExamination/createStudentRemakeExamination',
    }),
    checkShowAction(data) {
      return data.status == null
    },

    async getInit() {
      await this.getCourses({ studentId: this.user.studentId })
    },

    async onCoursesChange(id) {
      await this.getCourseSemesters({ courseId: id, status: 1 })
    },

    getNameStatus(status) {
      let name = ''
      if (status === 1) {
        name = 'Đang gửi yêu cầu thi lại'
      }
      if (status === 2) {
        name = 'Phê duyệt yêu cầu thi lại'
      }
      return name
    },

    async onCoursesSemesterChange(id) {
      await this.getDataSource({ studentId: this.user.studentId, courseSemestersId: id })
      this.rows = this.dataSource
    },

    async sendRemakeExaminations(data) {
      this.isLoading = true
      try {
        const body = {
          studentExaminationRoomId: data.studentExaminationRoomId,
          status: 1,
        }
        const res = await this.createStudentRemakeExamination(body)
        if (res && res.isSuccessful) {
          this.showToast('Gửi yêu cầu thành công!', 'CheckIcon', 'success')
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning')
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        await this.getDataSource({ studentId: this.user.studentId, courseSemestersId: this.filter.courseSemesterId })
        this.rows = this.dataSource
        this.isLoading = false
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
